import React from 'react';


import {
    Button,
    DatePicker,
    Header,
    Radio,
    TranslationMixin,
    WithCommonTranslations,
    WithUserTranslations,
} from 'tds_shared_ui';
import {WithAdminTranslations} from '../hocs';
import {DocumentsModal} from './DocumentsModal';

import './ProfileExtra.css';

export const ProfileExtraFields = WithAdminTranslations(WithCommonTranslations(
    WithUserTranslations(class extends TranslationMixin(React.Component) {
      constructor(props) {
        super(props);

        this.state = {
          taxExpirationDate: null,
          showDocuments: false
        };
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.handleCloseDocuments = this.handleCloseDocuments.bind(this);
        this.handleShowDocuments = this.handleShowDocuments.bind(this);
      }

      static getDerivedStateFromProps(props) {
        let date = null;
        if (props.formValues && props.formValues.taxExpirationDate) {
          date = props.formValues.taxExpirationDate;
        }



        return {
          taxExpirationDate: date,
        };
      }

      handleRadioChange(name, strValue) {
        let value = strValue;
        if (strValue === 'true') {
          value = true;
        } else if (strValue === 'false') {
          value = false;
        }

        this.props.onChange(name, value);
      }

      handleCloseDocuments(){
        this.setState({
          showDocuments: false
        })
      }

      handleShowDocuments(){
        this.setState({
          showDocuments: true
        })
      }





      render() {
        return (
            <div className="profile-extra">
              <Header level={2}>{this.getTranslation(
                  'TITLE_TAX_INFORMATION')}</Header>

              <Radio label={this.getTranslation('LABEL_TAX_EXEMPT')}
                     readOnly={this.props.readOnly}
                     name="taxExempt"
                     value={this.props.formValues.taxExempt}
                     onChange={(v) => this.handleRadioChange('taxExempt', v)}
                     options={[
                       {
                         id: 'taxExemptY',
                         value: true,
                         label: this.getTranslation('BUTTON_YES'),
                       }, {
                         id: 'taxExemptN',
                         value: false,
                         label: this.getTranslation('BUTTON_NO'),
                       }]}/>

              <DatePicker
                  label={this.getTranslation('LABEL_TAX_EXPIRATION_DATE')}
                  id="taxExpirationDate"
                  dateFormat="MM/dd/yyyy"
                  displayError={this.props.isValidated}
                  invalidDateMsg={this.getTranslation('ERROR_INVALID_DATE')}
                  selected={this.state.taxExpirationDate}
                  readOnly={this.props.readOnly}
                  required={this.props.formValues.taxExempt}
                  onChange={(date) =>
                      this.props.onChange('taxExpirationDate', date)}
                  popperPlacement="auto-right"/>

                {/*qzc9zt 9/28/20*/}

                <Button variant="link"
                        className="pl-0"
                        type="button"
                        onClick={this.handleShowDocuments}>
                    {this.getTranslation("TITLE_MANAGE_DOCUMENTS")}
                </Button>


                {this.props.superAdmin ?
                <Header level={2}>{"KEYCODE OPTIONS"}</Header>
                    : <div></div>}

                {this.props.superAdmin ?
                <Radio label={"Keycode Override"}
                       readOnly={this.props.readOnly}
                       name="keycodeOverride"
                       value={this.props.formValues.keycodeOverride}
                       onChange={(v) => this.handleRadioChange('keycodeOverride', v)}
                       options={[
                           {
                               id: 'keycodeOverrideY',
                               value: true,
                               label: this.getTranslation('BUTTON_YES'),
                           }, {
                               id: 'keycodeOverrideN',
                               value: false,
                               label: this.getTranslation('BUTTON_NO'),
                           }]}/>

                    : <div></div>}

                    {this.props.superAdmin ?

                <input

                    name = 'keycodeLimit'
                    type = 'text'
                    autoComplete= "off"
                    maxLength='2'
                    readOnly={this.props.readOnly}
                    value={this.props.formValues.keycodeLimit || ''}
                    required={this.props.formValues.keycodeOverride}
                    onChange={

                           (v) => {
                                let value = v.target.value;
                                let newText = '';
                               const numbers = '^[0-9]';
                               for (let i = 0; i < value.length; i++) {
                                   if (numbers.indexOf(value[i] > -1)) {
                                       newText += value[i];
                                   }
                               }
                               if ((newText <= 50) && (newText > -1))
                               {
                                   this.props.onChange('keycodeLimit', newText);
                               }

                           }
                       } />
                        : <div></div>}




              <Header level={2}>{this.getTranslation(
                  'TITLE_TECHLINE_CACHE')}</Header>

              <Radio label={this.getTranslation('LABEL_ENABLE_TECHLINE_CACHE')}
                     readOnly={this.props.readOnly}
                     name="enabledTechLineCache"
                     value={this.props.formValues.enabledTechLineCache}
                     onChange={(v) => this.handleRadioChange(
                         'enabledTechLineCache', v)}
                     options={[
                       {
                         id: 'enabledTechLineCacheY',
                         value: true,
                         label: this.getTranslation('BUTTON_YES'),
                       }, {
                         id: 'enabledTechLineCacheN',
                         value: false,
                         label: this.getTranslation('BUTTON_NO'),
                       }]}/>


              <DocumentsModal open={this.state.showDocuments}
                              username={this.props.username}
                              onClose={this.handleCloseDocuments}/>
            </div>
        );
      }
    })));