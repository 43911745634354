import {GenericAjaxService, adminAPI} from 'tds_shared_ui';

export default class RegionConfigService extends GenericAjaxService {

  static getRegionList() {
    return super.getAPI(adminAPI, `regionconfig/regionlist`);
  }

  static getRegionConfig(region) {
    return super.getAPI(adminAPI,`regionconfig/${region}`)
  }

  static setRegionConfig(region, data) {
    return super.postAPI(adminAPI,`regionconfig/${region}`, data)
  }

  static getRegionConfigForRegistration(countryCode, locale) {
    return super.getAPI(adminAPI,`regionconfig/registration/${countryCode}/${locale}`)
  }
}