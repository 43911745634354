import {adminAPI, GenericAjaxService} from 'tds_shared_ui';

export default class AdminUserService extends GenericAjaxService {

  static search(query, limit, page, timezone) {
    return super.getAPI(adminAPI, `users/?&q=${encodeURIComponent(query)}&limit=${limit || 25}&page=${page}&timezone=${encodeURIComponent(timezone)}`);
  }

  static get(username, opts, timezone){
    return super.getAPI(adminAPI, `users/${username}?timezone=${encodeURIComponent(timezone)}`, opts);
  }

  static update(user){
    return super.postAPI(adminAPI, `users`, user);
  }

  static updateRole(userName, role){
    return super.postAPI(adminAPI, `users/UpdateRole/?&role=${encodeURIComponent(role)}&userName=${encodeURIComponent(userName)}`);
  }

  static getOptions(username){
    return super.getAPI(adminAPI, `users/${encodeURIComponent(username)}/options`)
  }

  static disable(userName){
    return super.postAPI(adminAPI, `users/${encodeURIComponent(userName)}/disable`);
  }

  static enable(userName) {
    return super.postAPI(adminAPI, `users/${encodeURIComponent(userName)}/enable`);
  }

  static delete(userName) {
    return super.deleteAPI(adminAPI, `users/${encodeURIComponent(userName)}`);
  }

  static deleteLocksmithID(userName) {
    return super.postAPI(adminAPI, `users/${encodeURIComponent(userName)}/deleteLocksmithID`);
  }

  static UnwatchUser(username) {
    return super.postAPI(adminAPI, `users/${username}/unWatch`);
  }

  static WatchUser(username) {
    return super.postAPI(adminAPI, `users/${username}/watch`);
  }

  static SetVCIStatus(username, boolean) {
    return super.postAPI(adminAPI, `users/${username}/vci?q=${boolean}`);
  }

  static setLocksmithStatus(username, disabled) {
    return super.postAPI(adminAPI, `users/${username}/locksmith?p=${disabled}`);
  }

  static createUser(user, opts) {
    return super.postAPI(adminAPI, `register`, user, opts);
  }
}