import React from 'react';
import _ from 'lodash';

import {
  Alert,
  AlertMixin, Header,
  HelperUtils,
  LoadingIcon,
  TranslationMixin,
  WithCommonTranslations,
  withRouter,
} from 'tds_shared_ui';
import {OrdersService} from "../services";
import {RefundDetails, RefundConfirmation} from '../components/user/orders';
import {WithAdminTranslations} from "../components/hocs";

export const OrderRefundPage = withRouter(
  WithAdminTranslations(WithCommonTranslations(
    class OrderRefundPage extends AlertMixin(TranslationMixin(React.Component)) {
      constructor(props) {
        super(props);

        this.alertRef = React.createRef();

        this.state = {
          loading: false,
          orderDetails: null,
          refundDetails: null,
          refundType: null,
          error: false,
          alert: {
            display: false,
            type: 'error',
            message: '',
          },
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleContinue = this.handleContinue.bind(this);
        this.handleValidationError = this.handleValidationError.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleGoBack = this.handleGoBack.bind(this);
        this.handleGoToOrders = this.handleGoToOrders.bind(this);
      }

      componentDidMount() {
        let location = this.props.location;
        if (location.state) {
          this.setState({
            orderDetails: this.decorateOrderDetails(
                location.state.orderDetails),
            refundDetails: location.state.refundDetails,
          });
        } else {
          let path = location.pathname.split('/');
          let orderId = path[path.length - 1];
          this.fetchOrderDetails(orderId);
        }
      }

      componentDidUpdate(prevProps) {
        const {location} = this.props;
        const prevLocation = prevProps.location;

        if (location && location.state && location.state.refundDetails) {
          if (prevLocation.state.refundDetails !==
            location.state.refundDetails) {
            this.clearError();
            this.setState({
              refundDetails: location.state.refundDetails,
            });
          }
        }
      }

      decorateOrderDetails(orderDetails) {
        if (orderDetails && orderDetails.receiptItems) {
          orderDetails.receiptItems.forEach(
            item => item.refundPercent = false);
        }
        return orderDetails;

      }

      scrollToAlert() {
        HelperUtils.scrollToAlert(this.alertRef);
      }

      handleGoToOrders() {
        const {orderDetails, refundDetails} = this.state;
        let username = '';
        if (orderDetails) {
          username = orderDetails.username;
        } else if (refundDetails) {
          username = refundDetails.username;
        }
        this.props.navigate(`/admin/user/orders?username=${encodeURIComponent(username)}`);
      }

      handleChange(itemId, name, value) {
        this.clearError();
        if (itemId !== null) {
          this.setState(prevState => {
            let orderDetails = _.cloneDeep(prevState.orderDetails);
            let {receiptItems} = orderDetails;
            var idx;
            for (idx = 0; idx < receiptItems.length; idx++) {
                if (receiptItems[idx].itemId === itemId) {
                    receiptItems[idx][name] = value;
                    break;
                }
            }


            return {
              orderDetails: orderDetails,
            };
          });
        } else {
          let obj = {};
          obj[name] = value;
          this.setState(obj);
        }
      }

      handleContinue() {
        this.clearError();
        const {orderDetails, refundType} = this.state;
        let method = null;

        switch (refundType) {
          case 'full':
            method = () => {return this.fullRefund(orderDetails.orderNumber)};
            break;
          case 'partial':
            method = () => {return this.partialRefund(orderDetails)};
            break;
          case 'tax':
            method = () => {return this.taxRefund(orderDetails.orderNumber)};
            break;
          default:
            this.displayAlert(this.getTranslation('ERROR_NO_REFUND_TYPE'),
                'error', this.scrollToAlert);
        }

        if (method){
          this.setState({loading: true});
          Promise.resolve(method()).finally(() => this.setState({loading: false}));
        }

      }

      fullRefund(orderNumber) {
        let refund = {
          orderNumber: orderNumber,
          fullRefund: true,
        };
        return OrdersService.prepareRefund(refund).then(data => {
          this.setState({
            refundDetails: data,
          });
        }).catch(this.handleError);
      }

      partialRefund(orderDetails) {
        const orderRefundDetails = this.getRefundDetails(
            orderDetails.receiptItems);

        if (orderRefundDetails.length > 0) {
          let refund = {
            orderNumber: orderDetails.orderNumber,
            fullRefund: false,
            orderRefundDetails: orderRefundDetails,
          };
          return OrdersService.prepareRefund(refund).then(data => {
            this.setState({
              refundDetails: data,
            });
          }).catch(this.handleError);
        } else {
          this.displayAlert(this.getTranslation('ERROR_NO_ITEM_REFUNDS'),
              'error', this.scrollToAlert);
        }
      }

      taxRefund(orderNumber) {
        let refund = {
          orderNumber: orderNumber,
          taxExempt: true,
        };
        return OrdersService.prepareRefund(refund).then(data => {
          this.setState({
            refundDetails: data,
          });
        }).catch(this.handleError);
      }

      handleGoBack() {
        const {location} = this.props;
        this.clearError();
        if (!this.state.orderDetails) {
          let path = location.pathname.split('/');
          let orderId = path[path.length - 1];
          this.fetchOrderDetails(orderId).then(() => {
            this.setState({
              refundDetails: null,
              loading: false,
            });
          }).catch((e) => {
            //Adding empty catch to prevent webpack unhandled exception msg
          });
        } else {
          this.setState({
            refundDetails: null,
            loading: false,
          });
        }
        ;
      }

      handleValidationError() {
        this.displayAlert(this.getTranslation('ERROR_FORM_VALIDATION'), 'error',
            this.scrollToAlert);
      };

      handleError(e) {
        this.displayError(e, this.scrollToAlert);
      };

      getOrderNumberFromPath() {
        const {location} = this.props;
        let path = location.pathname.split('/');
        let orderId = path[path.length - 1];
        return orderId;
      }

      getRefundDetails(receiptItems) {
        let refundItems = [];
        receiptItems.forEach(item => {
          if (item.refundValue) {
            let newItem = {
              itemId: item.itemId,
            };
            if (item.refundPercent) {
              newItem.refundPercent = parseFloat(item.refundValue, 10) * 0.01;
            } else {
              newItem.refund = item.refundValue;
            }
            refundItems.push(newItem);
          }
        });

        return refundItems;
      }

      fetchOrderDetails(orderId) {
        return OrdersService.get(orderId).then(d => {
          this.setState({
            orderDetails: this.decorateOrderDetails(d),
          });
        }).catch(e => {
          this.setState({
            error: true,
          });
          this.displayError(e);
        });
      }

      renderBody(orderDetails, refundDetails) {
        if (refundDetails) {
          return (
              <RefundConfirmation refundDetails={refundDetails}
                                  onGoToOrderHistory={this.handleGoToOrders}
                                  onBack={this.handleGoBack}
              />
          );
        } else if (orderDetails) {
          return (
              <RefundDetails orderDetails={orderDetails}
                             refundType={this.state.refundType}
                             saving={this.state.loading}
                             onChange={this.handleChange}
                             onTaxRefund={this.handleTaxRefund}
                             onGoToOrderHistory={this.handleGoToOrders}
                             onValidationError={this.handleValidationError}
                             onError={this.handleError}
                             submit={this.handleContinue}/>
          );
        } else {
          return (
              <LoadingIcon display={true}
                           text={this.getTranslation('MESSAGE_LOADING')}/>
          );
        }
      }

      render() {
        const {orderDetails, refundDetails, error} = this.state;
        const orderNumber = this.getOrderNumberFromPath();

        if (error) {
          return (
              <Alert type={this.state.alert.type}>{this.state.alert.message}
              </Alert>
          );
        }

        return (
            <div>
              <Header level={1}>{this.getTranslation(
                  'TITLE_REFUND_ORDER')} #{orderNumber}</Header>
              {this.state.alert.display &&
              <Alert ref={this.alertRef}
                     type={this.state.alert.type}>{this.state.alert.message}
              </Alert>}

              {this.renderBody(orderDetails, refundDetails)}
            </div>
        );
      }
    })));