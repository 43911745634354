import React from "react";
import {
    Alert,
    AlertMixin,
    ProfileForm,
    LoadingIcon,
    TranslationMixin,
    withRouter,
    WithUserTranslations,
    WithCommonTranslations, Button, HelperUtils,
} from "tds_shared_ui";
import {
  AdminUserService,
  RegionConfigService,
} from "../services";
// import { Alert } from "../components/UI";
import {
    WithAdminTranslations,
} from "../components/hocs";
import UtilService from "../services/UtilService";


export const AdminRegistrationPage = withRouter(
    WithCommonTranslations(WithAdminTranslations(
      WithUserTranslations(
        class RegistrationPage extends AlertMixin(
          TranslationMixin(React.Component)
        ) {
          constructor(props) {
            super(props);
            this.alertRef = React.createRef();
              this.state = {
                  states: [],
              countries: null,
              countryStateMap: null,
              loading: false,
              termsData: null,
              showVerification: false,
              verificationToken: null,
              alert: {
                display: false,
                type: "default",
                message: "",
              },
              formValues: {
                username: "",
                password: "",
                confirmPassword: "",
                firstName: "",
                middleName: "",
                lastName: "",
                email: "",
                phone: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "",
                zipCode: "",
                country: "",
                locksmithID: "",
                locksmithPasscode: "",
                acdelcoAcctNum: "",
                companyEmail: "",
                vat: "",
                companyName: "",
                preferredLanguage: "",
              },
              consentData: null,
              showConsent: false,
              hideConsentChecks: false,
              captchaSuccess: false,
              regionData: null,
              loadingRegion: true,
            };

            this.hideTerms = this.hideTerms.bind(this);
          }

          componentDidMount() {
            this.setState({
                loadingRegion: true,
            });
            UtilService.getCountries().then((data) => {
                let countryNames = data.map(country => ({
                    label: country.name,
                    value: country.countryCode,
                }));
                let countryStateMap = {};
                data.forEach(country => {
                    countryStateMap[country.countryCode] = country.states.map(state => ({
                        label: state.name,
                        value: state.stateCode,
                    }));
                });
                this.setState({
                    countries: countryNames,
                    countryStateMap: countryStateMap,
                    loadingRegion: false,
                }, () => {
                    if (this.state.countries !== null && this.state.countries.length > 0) {
                        let country = this.state.countries.length === 1 ? this.state.countries[0].value : "";
                        if (this.state.countryStateMap !== null) {
                            const states = this.state.countryStateMap[country.value];
                            this.setState((prevState) => ({
                                states: states,
                                formValues: {
                                    ...prevState.formValues,
                                    country: country,
                                },
                            }));
                        }
                }

                if (this.props.locale) {
                    this.setState((prevState) => {
                        let fv = { ...prevState.formValues };
                        fv.preferredLanguage = this.props.locale.split("_")[0];
                        return {
                            formValues: fv,
                        };
                    });
                }
                });
            }).catch((e) => {
                //Adding empty catch to prevent webpack unhandled exception msg
                this.setState({
                    loadingRegion:false,
                    alert: {
                        display: true,
                        message: this.getTranslation("ERROR_FORM_VALIDATION"),
                        type: "error",
                    },
                })
            });
          }

          componentDidUpdate(prevProps, prevState) {
              if (prevState.formValues.country !== this.state.formValues.country) {
                if (this.state.formValues.country !== "") {
                  let country = this.state.formValues.country;
                  if (this.state.countryStateMap !== null) {
                    const states = this.state.countryStateMap[country];
                    this.setState((prevState) => ({
                      states: states,
                      formValues: {
                        ...prevState.formValues,
                        country: country,
                      },
                    }));
                  }
                }
              }
          }

          handleCaptchaChange(boolSuccess) {
            this.clearError();
            this.setState({
              captchaSuccess: boolSuccess,
            });
          }

          handleChange = (name, value, callback) => {
            this.setState((prevState) => {
              let newState = {};
              let formValues = Object.assign({}, prevState.formValues);
              formValues[name] = value;
              newState.formValues = formValues;
              if (name === "country" && prevState.formValues.country !== value) {
                this.setState({
                loadingRegion: true,
                });
                let locale = this.props.locale.split("-")[0];
                RegionConfigService.getRegionConfigForRegistration(value, locale).then((data) => {
                    let defaultLanguage = data.defaultLanguage.languageCode;
                    let languageOptions = data.supportedLanguages.map(language => ({
                        label: language.name,
                        value: language.languageCode,
                    }));
                    this.setState({
                        regionData: data,
                        languageOptions: languageOptions,
                        loadingRegion: false,
                    }, () => {
                        //reset state if country is changed
                        newState.formValues.state = "";
                        if (value != null) {
                            newState.states = this.state.countryStateMap[value];
                            newState.preferredLanguage = defaultLanguage;
                        } else {
                            newState.states = [];
                            newState.preferredLanguage = "";
                        }
                    });
                }).catch((e) => {
                    //Adding empty catch to prevent webpack unhandled exception msg
                    this.setState({
                        loadingRegion:false,
                        alert: {
                            display: true,
                            message: this.getTranslation("ERROR_FORM_VALIDATION"),
                            type: "error",
                        },
                    })
                });
              }
              return newState;
            }, callback);
          };

          handleCancel = () => {
            this.props.navigate(-1);
          };


          getUserObject(formValues) {
            let {
              addressLine1,
              addressLine2,
              city,
              state,
              zipCode,
              country,
              confirmPassword,
              locksmithID,
              locksmithPasscode,
              ...user
            } = formValues;

            user.locksmithID = locksmithID;
            user.passcode = locksmithPasscode;
            user.address = {
              addressLine1: addressLine1,
              addressLine2: addressLine2,
              city: city,
              state: state,
              zipCode: zipCode,
              country: country,
            };
            return user;
          }

          onValidationError = () => {
            this.setState(
              {
                alert: {
                  display: true,
                  message: this.getTranslation("ERROR_FORM_VALIDATION"),
                  type: "error",
                },
              },
            );
          };

          updateUser = () => {
            let user = this.getUserObject(this.state.formValues);
            user = Object.assign(user);
            return AdminUserService.createUser(user, {
              headers: { "Accept-Language": this.props.locale.replace("_", "-") },
            })
              .then((user) => {
                this.setState(
                  {
                    loading: false,
                    readOnly: false,
                    alert: {
                      display: true,
                      message: this.getTranslation(
                        "MESSAGE_SUCCESS_REGISTRATION"
                      ),
                      type: "success",
                    },
                  },this.scrollToAlert
                );

                const address = user.address || {};
                this.setState({
                  formValues: {
                    username: user.username || "",
                    password: "",
                    confirmPassword: "",
                    firstName: user.firstName || "",
                    middleName: user.middleName || "",
                    lastName: user.lastName || "",
                    email: user.email || "",
                    phone: user.phone || "",
                    addressLine1: address.addressLine1 || "",
                    addressLine2: address.addressLine2 || "",
                    city: address.city || "",
                    state: address.state || "",
                    zipCode: address.zipCode || "",
                    country: address.country || "",
                    locksmithID: user.locksmithID || "",
                    locksmithPasscode: "",
                    acdelcoAcctNum: user.acdelcoAcctNum || "",
                    companyEmail: user.companyEmail || "",
                    vat: user.vat || "",
                    companyName: user.companyName || "",
                    preferredLanguage: user.preferredLanguage || "",
                  },
                });
              })
              .catch((e) => {
                this.displayError(e,this.scrollToAlert);
                this.setState({
                  loading: false,
                  readOnly: false,
                  hideConsentChecks: true,
                });
              });
          }

          hideTerms() {
            this.setState({
              showConsent: false,
            });
          }
          scrollToAlert() {
            HelperUtils.scrollToAlert(this.alertRef);
          }

          handleChinaAutoFill = () => {
              let name = "country";
              let value = "CN";
              this.setState((prevState) => {
                  let newState = {};
                  let formValues = Object.assign({}, prevState.formValues);
                  formValues[name] = value;
                  newState.formValues = formValues;
                  // if (name === "country" && prevState.formValues.country !== value) {
                      this.setState({
                          loadingRegion: true,
                      });
                      let locale = this.props.locale.split("-")[0];
                      RegionConfigService.getRegionConfigForRegistration(value, locale).then((data) => {
                          let defaultLanguage = data.defaultLanguage.languageCode;
                          let languageOptions = data.supportedLanguages.map(language => ({
                              label: language.name,
                              value: language.languageCode,
                          }));
                          this.setState({
                              regionData: data,
                              languageOptions: languageOptions,
                              loadingRegion: false,
                              formValues: {
                                  addressLine1: "56 Jinwan Rd., Pudong District",
                                  city: "Shanghai",
                                  state: "CN-SH",
                                  country: "CN",
                                  zipCode: "200000",
                                  preferredLanguage: defaultLanguage,
                              },
                          }, () => {
                              //reset state if country is changed
                              newState.formValues.state = "";
                              if (value != null) {
                                  newState.states = this.state.countryStateMap[value];
                                  newState.preferredLanguage = defaultLanguage;
                              } else {
                                  newState.states = [];
                                  newState.preferredLanguage = "";
                              }
                          });
                      }).catch((e) => {
                          //Adding empty catch to prevent webpack unhandled exception msg
                          this.setState({
                              loadingRegion:false,
                              alert: {
                                  display: true,
                                  message: this.getTranslation("ERROR_FORM_VALIDATION"),
                                  type: "error",
                              },
                          })
                      });
                  // }
                  return newState;
              });
          };

          render() {
            if (this.state.loadingRegion){
                return this.renderLoading();
            } else {
                return this.renderApp();
            }
          }

          renderLoading() {
            return (
              <div className="container">
                <LoadingIcon display={true} size="3x" />
              </div>
            );
          }

          renderApp() {
            let showVATField = false;
            let requireState = false;
            let requireVAT = false;
            let renderCaptcha = null;
            let taxIdLabelId = "";
            let showLocksmithFields = false;
            let regionCode = null;
            if (this.state.regionData) {
              let { regionConfigs } = this.state.regionData;
              showVATField = regionConfigs.showVATField === "true";
              requireState = regionConfigs.stateRequired === "true";
              showLocksmithFields = regionConfigs.showLocksmithFields === "true";
              requireVAT = regionConfigs.requireVAT === "true";
              taxIdLabelId = regionConfigs.taxIdLabelId;
              regionCode = this.state.regionData.regionCode;
            }

            return (
              <div className="registrationPage">
                {this.state.alert.display && (
                  <Alert ref={this.alertRef} type={this.state.alert.type}>
                    {this.state.alert.message}
                  </Alert>
                )}

                  <Button variant="primary" type="button" className="btn-autofill"
                          // disabled={this.state.deleting === comment.id}
                          onClick={this.handleChinaAutoFill}>
                          {this.getTranslation('LABEL_AUTOFILL_CHINA_USER')}</Button>

                {this.state.countries && (
                  <ProfileForm
                    showTerms={false}
                    showLogin={true}
                    showVATField={showVATField}
                    taxIdLabelId={taxIdLabelId}
                    requireVAT={requireVAT}
                    regionCode={regionCode}
                    showLocksmithFields={showLocksmithFields}
                    onCaptchaChange={this.handleCaptchaChange}
                    submit={this.updateUser}
                    loading={this.state.loading}
                    onValidationError={this.onValidationError}
                    readOnly={this.state.readOnly}
                    formValues={this.state.formValues}
                    countries={this.state.countries}
                    states={this.state.states}
                    requireState={requireState}
                    languages={this.state.languageOptions}
                    termsHtmlString={this.state.termsData}
                    renderCaptcha={renderCaptcha}
                    onChange={this.handleChange}
                    onCancel={this.handleCancel}
                  />
                )}

              </div>
            );
          }
        }
      )
      )
    )
);
